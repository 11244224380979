import React, { FunctionComponent } from "react";
import { IconConst } from "./iconPaths";
import { IIcon } from "./Icon.types";
import { SvgIcon, Theme, useTheme } from "@mui/material";

// take every prop from the IconConst, but then overwrite it with the incoming props
const createInternalProps = (
  { category, label, viewBox, d, fill, height, width }: IIcon,
  theme: Theme
) => ({
  ...(category && label && IconConst(theme)[category][label]),
  ...(viewBox && { viewBox }),
  ...(d && { d }),
  ...(fill && { fill }),
  ...(height && { height }),
  ...(width && { width }),
});

const showIfExists = (key: string, value?: string) =>
  value ? ` ${key}="${value}"` : "";

// this should generate the same code as the Icon component
export const generateIconCode = (props: IIcon, theme: Theme) => {
  const internalProps = createInternalProps(props, theme);
  return `
<svg${showIfExists("viewBox", internalProps.viewBox)}${showIfExists(
    "height",
    internalProps.height
  )}${showIfExists(
    "width",
    internalProps.width
  )} preserveAspectRatio="xMinYMid meet">
${
  Array.isArray(internalProps.d)
    ? internalProps.d
        .map(
          (dItem: string) =>
            `<path  d="${dItem}"${showIfExists("fill", internalProps.fill)}/>`
        )
        .join("")
    : `<path d="${internalProps.d}"${showIfExists(
        "fill",
        internalProps.fill
      )}/>`
}
</svg>
`;
};

const Icon: FunctionComponent<IIcon> = (props) => {
  const theme = useTheme();

  const internalProps = createInternalProps(props, theme);

  return (
    <SvgIcon
      {...(internalProps.viewBox && { viewBox: internalProps.viewBox })}
      style={{ width: internalProps.width, height: internalProps.height }}
      preserveAspectRatio="xMinYMid meet"
    >
      {Array.isArray(internalProps.d) ? (
        internalProps.d.map((dItem: string) => (
          <path
            key={dItem}
            d={dItem}
            fill={internalProps.fill || "currentColor"}
          />
        ))
      ) : (
        <path d={internalProps.d} fill={internalProps.fill || "currentColor"} />
      )}
    </SvgIcon>
  );
};

export default Icon;
