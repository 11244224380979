import { Box, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { FunctionComponent } from "react";
import { IFooter } from "./Footer.types";

export const getFooterHeight = (largeScreen: boolean) =>
  largeScreen ? 59 : 180;

export const isHorizontalFooter = (largeScreen: boolean) => largeScreen;

const CURRENT_YEAR = new Date().getFullYear();

const Footer: FunctionComponent<IFooter> = ({
  legalLinks,
  socialMediaLinks,
}) => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isHorizontalFooter(largeScreen) ? "row" : "column",
        justifyContent: isHorizontalFooter(largeScreen)
          ? "space-between"
          : "space-around",
        alignItems: "center",
        height: getFooterHeight(largeScreen),
      }}
    >
      {legalLinks}
      {isHorizontalFooter(largeScreen) && (
        <Typography variant="caption">
          &copy; Copyright {CURRENT_YEAR} ADVANTEST CORPORATION
        </Typography>
      )}
      <Box
        sx={{
          width: "300px",
          display: "flex",
          flexDirection: "row",
          justifyContent: isHorizontalFooter(largeScreen)
            ? "flex-end"
            : "center",
          alignItems: "center",
        }}
      >
        {socialMediaLinks}
      </Box>
      {!isHorizontalFooter(largeScreen) && (
        <Typography variant="caption">
          &copy; Copyright {CURRENT_YEAR} ADVANTEST CORPORATION
        </Typography>
      )}
    </Box>
  );
};

export default Footer;
