import React from "react";
import Icon from "../Icon/Icon";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { IServicesMenuItem, ServicesMenu } from "../ServicesMenu";
import {
  alpha,
  Box,
  IconButton,
  Popover,
  useMediaQuery,
  useTheme,
} from "@mui/material";

interface Props {
  servicesMenuItems: IServicesMenuItem[];
  servicesMenuMaxHeight?: number;
  actionComponents?: React.ReactNode[];
}

export const headerIconButtonHover = {
  backgroundColor: alpha("#000000", 0.04),
};

const HeaderActions = ({
  servicesMenuItems,
  servicesMenuMaxHeight,
  actionComponents,
}: Props) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: smallScreen ? "40px" : "56px",
      }}
    >
      {actionComponents}
      <>
        {servicesMenuItems.length > 0 && (
          <PopupState variant="popover" popupId="global-navigation">
            {(popupState) => (
              <>
                <IconButton
                  sx={{
                    "&:focus-visible": {
                      border: "1px solid black",
                    },
                    "&:hover": headerIconButtonHover,
                  }}
                  disableRipple
                  {...bindTrigger(popupState)}
                >
                  <Icon
                    category="Menu"
                    label="Apps"
                    fill={theme.palette.grey[500]}
                  />
                </IconButton>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <ServicesMenu
                    items={servicesMenuItems}
                    maxHeight={servicesMenuMaxHeight || 200}
                  />
                </Popover>
              </>
            )}
          </PopupState>
        )}
      </>
    </Box>
  );
};

export default HeaderActions;
