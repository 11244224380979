import React from "react";
import { Box } from "@mui/material";
import HeaderActions from "./HeaderActions";
import HeaderMenuItems from "./HeaderMenuItems";
import useScreen from "../hooks/useSceen";
import { LOGO_HEIGHT } from "./Header";
import { IMenuItem } from "./Header.types";
import { IServicesMenuItem } from "..";

interface Props {
  logo: React.ReactNode;
  actionComponents?: React.ReactNode[];
  menuItems: IMenuItem[];
  servicesMenuItems: IServicesMenuItem[];
  servicesMenuMaxHeight?: number;
}

const HeaderFirstRow = ({
  logo,
  menuItems,
  servicesMenuItems,
  actionComponents,
  servicesMenuMaxHeight,
}: Props) => {
  const { smallScreen } = useScreen();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "100%",
        height: LOGO_HEIGHT,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: LOGO_HEIGHT,
        }}
      >
        {logo}
        {!smallScreen && <HeaderMenuItems menuItems={menuItems} />}
      </Box>
      {!smallScreen && (
        <HeaderActions
          servicesMenuItems={servicesMenuItems}
          servicesMenuMaxHeight={servicesMenuMaxHeight}
          actionComponents={actionComponents}
        />
      )}
    </Box>
  );
};

export default HeaderFirstRow;
