import { Box } from "@mui/material";
import React from "react";
import { IMenuItem } from "./Header.types";

interface Props {
  menuItems: IMenuItem[];
}

const HeaderMenuItems = ({ menuItems }: Props) => {
  return <Box>{menuItems}</Box>;
};

export default HeaderMenuItems;
