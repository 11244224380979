import React, { FunctionComponent } from "react";
import { IHeader } from "./Header.types";
import { AppBar, Box, Toolbar, useTheme } from "@mui/material";
import HeaderActions from "./HeaderActions";
import HeaderMenuItems from "./HeaderMenuItems";
import useScreen from "../hooks/useSceen";
import HeaderSecondRow from "./HeaderSecondRow";
import HeaderFirstRow from "./HeaderFirstRow";

export const LOGO_HEIGHT = 58;

const Header: FunctionComponent<IHeader> = ({
  logo,
  headerSecondRow,
  menuItems,
  servicesMenuItems,
  servicesMenuMaxHeight,
  actionComponents,
}) => {
  const theme = useTheme();
  const { smallScreen } = useScreen();
  return (
    <AppBar
      position="static"
      color="inherit"
      elevation={0}
      sx={{
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        background: theme.palette.background.paper,
        height: `${getHeaderHeight(!headerSecondRow, smallScreen)}px`,
        minHeight: `${getHeaderHeight(!headerSecondRow, smallScreen)}px`,
        zIndex: 100,
        width: "100%",
      }}
    >
      <Toolbar
        sx={{
          paddingLeft: "0px !important",
          paddingRight: "16px",
          height: `${getHeaderHeight(!headerSecondRow, smallScreen)}px`,
          minHeight: `${LOGO_HEIGHT}px !important`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <HeaderFirstRow
            logo={logo}
            menuItems={menuItems}
            servicesMenuItems={servicesMenuItems}
            servicesMenuMaxHeight={servicesMenuMaxHeight}
            actionComponents={actionComponents}
          />
          {headerSecondRow && (
            <HeaderSecondRow
              breadcrumbItems={headerSecondRow.breadcrumbItems}
              applicationTitle={headerSecondRow.applicationTitle}
            />
          )}
          {smallScreen && (
            <>
              <HeaderMenuItems menuItems={menuItems} />
              <HeaderActions
                servicesMenuItems={servicesMenuItems}
                servicesMenuMaxHeight={servicesMenuMaxHeight}
                actionComponents={actionComponents}
              />
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

export const getHeaderHeight = (isMinified, smallScreen) =>
  isMinified ? (smallScreen ? 140 : LOGO_HEIGHT) : smallScreen ? 180 : 84;
