import { Box, Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import useScreen from "../hooks/useSceen";

interface Props {
  applicationTitle: string;
  breadcrumbItems: React.ReactNode[];
}

const HeaderSecondRow = ({ applicationTitle, breadcrumbItems }: Props) => {
  const { smallScreen } = useScreen();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        width: "100%",
        height: "32px",
        paddingLeft: "24px",
        marginTop: smallScreen ? "0px" : "-8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            width: 200,
            minWidth: 200,
          }}
        >
          {applicationTitle}
        </Typography>
        {!smallScreen && (
          <>
            <div
              style={{
                width: "8px",
              }}
            />
            <Breadcrumbs aria-label="breadcrumb">{breadcrumbItems}</Breadcrumbs>
          </>
        )}
      </Box>
    </Box>
  );
};

export default HeaderSecondRow;
