import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  spacing: 8,
  palette: {
    primary: { main: "#007AFF" },
    secondary: { main: "#90A4AE" },
    error: { main: "#FF3B30" },
    background: { default: "#ECEFF1", paper: "#FFFFFF" },
    grey: {
      "100": "#ECEFF1",
      "200": "#CFD8DC",
      "300": "#B0BEC5",
      "400": "#90A4AE",
      "500": "#607D8B",
      "600": "#263238",
    },
    info: { main: "#007AFF" },
    success: { main: "#4CD964" },
    warning: { main: "#FF9500" },
  },
  typography: {
    fontWeightLight: 300,
    fontFamily: "Rubik,sans-serif",
    button: {
      fontSize: "0.8rem",
      fontWeight: 300,
      color: "#FFFFFF",
    },
    allVariants: {
      letterSpacing: 0,
    },

    subtitle1: {
      fontSize: "18px",
      fontFamily: "DIN2014-Demi,Rubik,sans-serif",
      fontWeight: 300,
      color: "#000000",
    },

    subtitle2: {
      fontSize: "0.889rem",
      fontWeight: 300,
      color: "#007AFF",
    },
    h1: {
      fontSize: "1.802rem",
      fontWeight: 300,
      color: "#263238",
    },
    h2: {
      fontSize: "1.602rem",
      fontWeight: 300,
      color: "#263238",
    },
    h3: {
      fontSize: "1.424rem",
      fontWeight: 300,
      color: "#263238",
    },
    h4: {
      fontSize: "1.266rem",
      fontWeight: 300,
      color: "#263238",
      letterSpacing: 0,
    },
    h5: {
      fontSize: "1.125rem",
      fontWeight: 300,
      color: "#263238",
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 300,
      color: "#263238",
    },
    body2: {
      fontSize: "1rem",
      fontWeight: 300,
      color: "#607D8B",
    },
    caption: {
      fontSize: "0.889rem",
      fontWeight: 300,
      color: "#263238",
    },
  },
});

export default theme;
