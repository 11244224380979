import { useMediaQuery, useTheme } from "@mui/material";

const useScreen = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return { smallScreen };
};

export default useScreen;
