import React, {
  createRef,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { FunctionComponent } from "react";
import { IServicesMenu } from "./ServicesMenu.types";
import { Icon } from "../Icon";
import {
  Box,
  Input,
  InputAdornment,
  Link,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IIcon } from "../Icon/Icon.types";

const MIN_WIDTH = 250;

const ServicesMenu: FunctionComponent<IServicesMenu> = ({
  items,
  maxHeight,
}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [value, setValue] = useState("");
  const [focused, setFocused] = useState(1);
  const [width, setWidth] = useState(MIN_WIDTH);

  const inputRef = useRef<HTMLInputElement>(null);

  const currentItems = items.filter((item) =>
    item.service.title.includes(value)
  );
  const servicesRefs = useRef<MutableRefObject<HTMLLIElement>[]>(
    // cannot use hooks inside loops: https://reactjs.org/docs/hooks-rules.html
    // so calling createRef
    items.map(() => createRef<HTMLLIElement>())
  );

  const sortedCategories: {
    title: string;
    sequence: number;
    services: {
      icon?: React.ReactNode;
      logo?: {
        logoUrl: string;
        alt: string;
      };
      service: {
        sequence: number;
        title: string;
        href: string;
      };
    }[];
  }[] = currentItems
    .reduce(
      (
        categoryArray: {
          title: string;
          sequence: number;
          services: {
            icon?: React.ReactNode;
            logo?: {
              logoUrl: string;
              alt: string;
            };
            service: {
              sequence: number;
              title: string;
              href: string;
            };
          }[];
        }[],
        currentServiceItem
      ) => {
        const categoryArrayIndex = categoryArray.findIndex(
          (category) =>
            category.title === currentServiceItem.service.category.title
        );

        if (categoryArrayIndex > -1) {
          const newServices = [
            ...categoryArray[categoryArrayIndex].services,
            {
              icon: currentServiceItem.icon,
              logo: currentServiceItem.logo,
              service: {
                title: currentServiceItem.service.title,
                href: currentServiceItem.service.href,
                sequence: currentServiceItem.service.sequence,
              },
            },
          ].sort((a, b) => (a.service.sequence > b.service.sequence ? 1 : -1));
          categoryArray[categoryArrayIndex].services = newServices;

          return categoryArray;
        }

        const newCategoryArray = [
          ...categoryArray,
          {
            title: currentServiceItem.service.category.title,
            sequence: currentServiceItem.service.category.sequence,
            services: [
              {
                icon: currentServiceItem.icon,
                logo: currentServiceItem.logo,
                service: {
                  title: currentServiceItem.service.title,
                  href: currentServiceItem.service.href,
                  sequence: currentServiceItem.service.sequence,
                },
              },
            ],
          },
        ];

        return newCategoryArray;
      },
      []
    )
    .sort((a, b) => (a.sequence > b.sequence ? 1 : -1));

  useEffect(() => {
    if (inputRef && inputRef.current) {
      setFocused(0);
    }
  }, [inputRef && inputRef.current]);

  useEffect(() => {
    const widest = Math.max(
      ...servicesRefs.current.map((item) =>
        item && item.current && item.current.children[1]
          ? item.current.children[1].clientWidth
          : 0
      )
    );
    const targetWidth = smallScreen ? widest : widest + 66;
    setWidth(
      targetWidth < MIN_WIDTH
        ? MIN_WIDTH
        : // jest dom doesnt seem to handle client width properly
          /* istanbul ignore next */
          targetWidth
    );
  }, [servicesRefs && servicesRefs.current, smallScreen]);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      if (focused === 0) {
        inputRef.current.focus();
      } else {
        servicesRefs.current[focused - 1].current.focus();
      }
    }
  }, [focused, inputRef && inputRef.current]);

  const handleKeyDown = (e) => {
    e.stopPropagation();
    const nextFocused =
      e.key === "ArrowDown"
        ? (focused + 1) % (currentItems.length + 1)
        : e.key === "ArrowUp"
        ? (focused - 1 < 0 ? currentItems.length : focused - 1) %
          (currentItems.length + 1)
        : focused;

    setFocused(nextFocused);

    if (nextFocused === 0) {
    } else {
      const indexMenuItems = nextFocused - 1;
      servicesRefs.current[indexMenuItems].current &&
        servicesRefs.current[indexMenuItems].current.scrollIntoView({
          block: "center",
        });
    }
  };

  return (
    <Paper sx={{ width, border: `1px solid  ${theme.palette.grey[400]}` }}>
      <Input
        inputProps={{ ref: inputRef, tabIndex: 1 }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        style={{
          width: width - 30,
          marginLeft: 16,
          marginTop: 16,
          marginBottom: 16,
        }}
        onKeyDown={handleKeyDown}
        startAdornment={
          <InputAdornment position="start">
            <Icon category="Menu" label="Search" />
          </InputAdornment>
        }
      />
      <MenuList
        sx={{
          maxHeight: `${maxHeight}px` || "200px",
          overflowY: "auto",
          "&:focus": { outline: "none" },
          "&::-webkit-scrollbar ": {
            width: "6px",
            height: "6px",
          },
          "&::-webkit-scrollbar-track ": {
            borderRadius: "10px",
            background: "rgba(0,0,0,0.1)",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            background: "rgba(0,0,0,0.2)",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "rgba(0,0,0,0.4)",
          },
          "&::-webkit-scrollbar-thumb:active": {
            background: "rgba(0,0,0,.5)",
          },
        }}
      >
        {sortedCategories.map(({ title, services }) => (
          <Box key={`${title}`} sx={{ padding: 0 }}>
            <Typography variant="h5" sx={{ paddingLeft: 1 }}>
              {title}
            </Typography>
            {services.map(({ service: { title, href }, icon, logo }, index) => (
              <MenuItem
                key={`${index}-${title}`}
                tabIndex={index + 2}
                onKeyDown={handleKeyDown}
                sx={{ padding: 0 }}
              >
                <Link
                  href={href}
                  sx={{
                    textDecoration: "none",
                    width: "100%",
                    height: "100%",
                    padding: "8px",
                  }}
                  color="inherit"
                >
                  <Box
                    sx={{ display: "flex", flexDirection: "row" }}
                    ref={servicesRefs.current[index] as any}
                  >
                    {logo ? (
                      <img
                        src={logo.logoUrl}
                        alt={logo.alt}
                        style={{ height: 24 }}
                      />
                    ) : icon ? (
                      <ListItemIcon sx={{ minWidth: "30px" }}>
                        {icon}
                      </ListItemIcon>
                    ) : (
                      <></>
                    )}
                    {icon && <Typography variant="body1">{title}</Typography>}
                  </Box>
                </Link>
              </MenuItem>
            ))}
          </Box>
        ))}
      </MenuList>
    </Paper>
  );
};

export default ServicesMenu;
